import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path:'/login',
    name:'登录',
    meta:{name:'登录'},
    component:() => import('../views/Login.vue')
  },
  {
    path:'/register',
    name:'注册',
    meta:{name:'注册'},
    component:() => import('../views/Register.vue')
  },
  {
    path: '/',
    name: 'Homepage',
    component: () => import('../views/Homepage.vue'),
    redirect: '/login',  // 重定向到主页
    children:[
      {path:'home',name:'Home',meta:{name:'系统首页'}, component:()=>import('../views/Manager/Home.vue')},

      {path:'major',name:'Major',meta:{name:'专业信息'}, component:()=>import('../views/Find/Major.vue')},
      {path:'branch',name:'Branch',meta:{name:'分院信息'}, component:()=>import('../views/Find/Branch.vue')},
      {path:'course',name:'Course',meta:{name:'课程信息'}, component:()=>import('../views/Find/Course.vue')},

      { path: 'gift', name: 'Gift',meta:{name:'积分商城'}, component:()=>import('../views/Manager/Gift.vue')},

      { path: 'notice', name: 'Notice',meta:{name:'公告列表'}, component:()=>import('../views/Manager/Notice.vue')},

      { path: 'book', name: 'Book',meta:{name:'图书列表'}, component:()=>import('../views/Manager/Book.vue')},

      { path: 'video', name: 'Video',meta:{name:'视频列表'}, component:()=>import('../views/Manager/Video.vue')},

      { path: 'ai', name: 'Ai',meta:{name:'AI助手'}, component:()=>import('../views/Manager/Ai.vue')},
      { path: 'listen', name: 'Listen',meta:{name:'听写'}, component:()=>import('../views/Manager/Listen.vue')},
      { path: 'ppt', name: 'Ppt',meta:{name:'生成PPT'}, component:()=>import('../views/Manager/Ppt.vue')},

      { path: 'coursepage', name: 'Coursepage',meta:{name:'课程首页'}, component:()=>import('../views/Course/Coursepage.vue'),
       redirect: '/coursepage/course1', children:[
          {
            path: 'course1', // 子路由的路径
            name:'Course1',
            meta:{name:'课程情况'},
            component:()=>import('../views/Course/Course1.vue')
          },
          {
            path: 'homework', // 子路由的路径
            name:'Homework',
            meta:{name:'作业列表'},
            component:()=>import('../views/Course/Homework.vue')
          },
          {
            path: 'sethomework', // 子路由的路径
            name:'SetHomework',
            meta:{name:'发布作业'},
            component:()=>import('../views/Course/SetHomework.vue')
          },
          {
            path: 'sign', // 子路由的路径
            name:'Sign',
            meta:{name:'签到'},
            component:()=>import('../views/Course/Sign.vue')
          },
          {
            path: 'choose', // 子路由的路径
            name:'Choose',
            meta:{name:'选人'},
            component:()=>import('../views/Course/Choose.vue')
          },
          {
            path: 'add', // 子路由的路径
            name:'Add',
            meta:{name:'加分'},
            component:()=>import('../views/Course/Add.vue')
          },
          {
            path: 'score', // 子路由的路径
            name:'Score',
            meta:{name:'成绩'},
            component:()=>import('../views/Course/Score.vue')
          },
          {
            path: 'count', // 子路由的路径
            name:'Count',
            meta:{name:'统计'},
            component:()=>import('../views/Course/Count.vue')
          },
          {
            path: 'addHistory', // 子路由的路径
            name:'AddHistory',
            meta:{name:'加分'},
            component:()=>import('../views/Course/AddHistory.vue')
          },
          {
            path: 'chat', // 子路由的路径
            name:'Chat',
            meta:{name:'问答'},
            component:()=>import('../views/Course/Chat.vue')
          },
        ]
      },

      { path: 'person', name: 'Person',meta:{name:'个人信息'}, component:()=>import('../views/Manager/Person.vue')},
      { path: 'password', name: 'Password',meta:{name:'密码'}, component:()=>import('../views/Manager/Password.vue')},
      { path: '403', name: 'Auth', meta:{name:'无权限'}, component:()=>import('../views/Manager/Auth.vue')},




    ]
  },
  {path:'*',name:'404',meta:{name:'无法访问'}, component:()=>import('../views/404.vue')}
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   // to 是到达的路由信息
//   // from 是开源的路由信息
//   // next 是帮助我们跳转路由的函数
//   let adminPaths = ['/class','/college','/personman','/copy','/copy1','/trialno','trialyes','/lessonall','/examall','/trialcher','/trialres','/chertrial','/cherres']
//   let adminPaths1 = ['/class','/person','/copy','/copy1','/applicationa','/applicationb','/applicationc','/lesson','/exam','/trialcher','/trialres','/chertrial','/cherres']
//   let adminPaths2 = ['/class','/person','/copy','/copy1','/applicationa','/applicationb','/applicationc','/lesson','/exam','/chertrial','/cherres']
//   let adminPaths3 = ['/college','/person','/trialno','trialyes','/applicationa','/applicationb','/applicationc','/lessonall','/examall','/trialcher','/trialres']
//   let user = JSON.parse(localStorage.getItem('admin') || '{}')
//   //如果当前登录的是学生，当到达的路径是管理员才有权限访问的路径时，不让他访问实际的页面
//   if (user.role == '学生' && adminPaths.includes(to.path)) {
//     next('/403')
//   } else if(user.role == '辅导员' && adminPaths1.includes(to.path)){
//     next('/403')
//   }else if(user.role == '负责人' && adminPaths2.includes(to.path)){
//     next('/403')
//   }else if(user.role == '教师' && adminPaths3.includes(to.path)){
//     next('/403')
//   }else{
//     next()
//   }
// })
export default router

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: ()=> ({
    item: ""
  }),
  getters: {
    getItem(state){
      return state.item
    }
  },
  mutations: {
    addTest(state, data){
      state.item = data
    }
  },
  actions: {

  },
  modules: {
  }
})
